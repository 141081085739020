<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">消息列表</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >消息列表</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>

    <div class="release">
      <div class="release-box" @click="dialogVisible = true">
        <div class="i-box">
          <i class="fa ion-plus-round"></i>
        </div>
        <div class="name">发布消息</div>
      </div>
    </div>

    <!-- 消息列表 -->
    <div class="news">
      <div class="news-title">至今为止</div>
      <div class="news-item" v-for="(item, index) in newList" :key="index">
        <div class="left">
          <i class="fa fa-envelope bg-blue"></i>
        </div>
        <div class="right">
          <div class="title-box">
            <div class="title-left">
              <div class="title-name">{{ item.Sender }}</div>
              <div class="title-tltle">{{ item.Title }}</div>
            </div>
            <div class="title-right">
              <i class="fa fa-clock-o"></i>
              {{ item.SendTime }}
            </div>
          </div>
          <div class="title-contet" v-html="item.MsgContent"></div>
          <div class="del-btn" @click="deleteItem(item, index)">Delete</div>
        </div>
      </div>
      <div class="new-bottom">
        <i class="fa fa-clock-o bg-gray"></i>
      </div>
    </div>

    <el-dialog
      title="新增消息"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
      top="13vh"
    >
      <div class="dialog-main">
        <div class="line-box">
          <div class="title">消息标题</div>
          <el-input v-model="Title" placeholder="消息标题" />
        </div>
        <div class="line-box2">
          <div class="line">
            <div class="line-title">是否热点</div>
            <el-switch
              v-model="IsHot"
              active-color="#428BCA"
              inactive-color="#EEEEEE"
            >
            </el-switch>
          </div>
          <div class="line">
            <div class="line-title">是否置顶</div>
            <el-switch
              v-model="IsTop"
              active-color="#428BCA"
              inactive-color="#EEEEEE"
            >
            </el-switch>
          </div>
        </div>
        <div class="line-box">
          <div class="title">消息详细内容</div>
          <vue-editor v-model="MsgContent" />
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">关闭</el-button
        ><el-button class="pop-save" @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getNews, addNews, deleteNews } from "@/api/other";
export default {
  data() {
    return {
      page: 1,
      size: 20,
      newList: [],
      flag: false,
      dialogVisible: false,
      IsTop: false,
      IsHot: false,
      Title: null,
      MsgContent: null,
    };
  },
  methods: {
    //消息列表
    getNews() {
      getNews({
        page: this.page,
        size: this.size,
      }).then((res) => {
        if (res.status === 200) {
          this.flag = true;
          if (res.response.data.length !== 0) {
            this.newList = this.newList.concat(res.response.data);
            this.page++;
          } else {
            this.$message.warning("没有更多了");
            this.flag = false;
          }
          console.log("this.newList", this.newList);
        }
      });
    },
    handleScroll(event) {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      // 检查是否滚动到了页面底部
      if (scrollHeight - scrollTop - clientHeight < 1) {
        // 200 是一个滚动到底部前的偏移量
        // 执行的代码......
        if (this.flag) {
          this.flag = false;
          console.log("滚动到了页面底部,并执行");
          this.getNews();
        }
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    submit() {
      const data = {
        ID: null,
        IsTop: this.IsTop,
        IsHot: this.IsHot,
        Title: this.Title,
        MsgContent: this.MsgContent,
      };
      addNews(data).then((res) => {
        if (res.status === 200) {
          this.$message.success("提交成功");
          this.newList = [];
          this.page = 1;
          this.handleClose();
          this.getNews();
        }
      });
    },
    deleteItem(data, index) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNews({ id: data.ID }).then((res) => {
          if (res.status === 200) {
            this.$message.success("删除成功");
            this.newList.splice(index, 1);
          }
        });
      });
    },
  },
  created() {
    this.getNews();
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .release {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    margin-bottom: 24px;
    .release-box {
      width: 130px;
      height: 36px;
      display: flex;
      align-items: center;
      background-color: #dd4b39;
      color: #fff;
      border-radius: 2px;
      cursor: pointer;
      .i-box {
        flex-shrink: 0;
        width: 36px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          font-size: 24px;
        }
      }
      .name {
        display: flex;
        width: 100%;
        height: 100%;
        border-left: 1px solid #000;
        align-items: center;
        justify-content: center;
        font-size: 14px;
      }
    }
  }
  .news {
    position: relative;
    .news-title {
      font-weight: 600;
      padding: 5px;
      display: inline-block;
      background-color: #dd4b39;
      border-radius: 4px;
      color: #fff;
      position: relative;
      z-index: 999;
      margin-bottom: 12px;
    }
    .news-item {
      margin-bottom: 15px;
      display: flex;
      .left {
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        font-size: 15px;
        line-height: 30px;
        background-color: #0073b7;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 20px;
        position: relative;
        z-index: 999;
      }
      .right {
        width: 100%;
        background-color: #fff;
        .title-box {
          padding: 10px;
          border-bottom: 1px solid #f4f4f4;
          display: flex;
          justify-content: space-between;
          .title-left {
            font-weight: bolder;
            font-size: 18px;
            display: flex;
            align-items: center;
            .title-name {
              outline: none;
              text-decoration: none;
              color: #72afd2;
            }
            .title-tltle {
              font-weight: normal;
              margin-left: 10px;
            }
          }
          .title-right {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            color: #999;
            float: right;
            padding: 10px;
            font-size: 12px;
            i {
              margin-right: 3px;
            }
          }
        }
        .title-contet {
          padding: 10px;
        }
        .del-btn {
          background-color: #dd4b39;
          color: #fff;
          padding: 3px 5px;
          border-radius: 2px;
          width: 48px;
          font-size: 14px;
          text-align: center;
          margin-left: 10px;
          margin-top: 20px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }
    .new-bottom {
      margin-top: 12px;
      width: 30px;
      height: 30px;
      font-size: 15px;
      line-height: 30px;
      background-color: #d2d6de;
      color: #666;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-right: 20px;
      position: relative;
      z-index: 999;
    }
  }

  .news::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    background: #ddd;
    left: 13px;
    margin: 0;
    border-radius: 2px;
    z-index: 99;
  }

  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      .title {
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 16px;
        font-weight: bolder;
        color: #000;
      }
    }
    .line-box2 {
      display: flex;
      justify-content: space-between;
      .line {
        width: 50%;
        .line-title {
          margin-bottom: 10px;
          margin-top: 10px;
          font-size: 16px;
          font-weight: bolder;
          color: #000;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
.upload-box {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}

.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
